import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'helpers/Firebase';
import { adminRoot,adminOrgRoot, currentUser, UserRole } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import axios from 'axios';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  // eslint-disable-next-line no-return-await
  // await auth
  //   .signInWithEmailAndPassword(email, password)
  //   .then((user) => user)
  //   .catch((error) => error);
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);
  // return axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/auth/user/login`,
  //  formData,
  // {
  //   headers: {
  //     'Content-Type': 'multipart/form-data', // Set the Content-Type as multipart/form-data
  //   },
  // }).then((user) => user)
  // .catch((error) => error);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API_URL}/auth/user/login`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the Content-Type as multipart/form-data
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      // console.log('loginUser',loginUser);
      // const item = { uid: loginUser.user.uid, ...currentUser };
      const item = {
        ...currentUser,
        ...loginUser.data,
        uid: loginUser.data.USERID,
        id: loginUser.data.USERID,
      };
      // const item = loginUser.data;
      // item.uid = loginUser.data.USERID;
      // item.id = loginUser.data.USERID;
      // item.title = loginUser.data.name;
      // item.img = loginUser.data.profile_picture ?? '/assets/img/profiles/l-1.jpg';
      // item.date = 'Last seen';
      // item.role = UserRole.Admin;
      // console.log('currentUser',currentUser);
      // console.log('item',item);
      if (item.two_step_verification === '1') {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('name', item.first_name);
        yield put(loginUserSuccess('OTP Has been sent successfully'));
        history.push('otp');
      } else {
        setCurrentUser(item, loginUser.token);
        yield put(loginUserSuccess(item));
        
        const productName = process.env.REACT_APP_ACTIVE_PRODUCT || '';
        if(productName === 'dynamic'){
        history.push(`${adminOrgRoot}/dynamicpricing-dashboard`);
        }else{
          history.push(`${adminRoot}/dashboards/default`);
        }

      }
    } else {
      console.log('error');
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item, registerUser.token);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
